<template>
    <div class="mypage order-create">
        <div class="nav-area">
            <van-button class="nav-button" round plain hairline ref="ball" @click="toHome()">
                <van-icon name="wap-home-o" size="30px" color="#fff" />
            </van-button>
        </div>
        <!-- <div style="overflow: hidden;">
            <van-divider content-position="left" dashed>基本信息</van-divider>
        </div> -->
        <div class="detail-basic inner-box">
            <div class="detail-item-1">
                <van-cell-group class="item-order-no">
                  <van-field :value="formData.order_no" label="订单编号" readonly />
                  <van-button plain type="info" size="mini" class="btn-edit"
                    @click="popDetail()" >修改订单进度</van-button>
                </van-cell-group>
                <van-cell-group>
                  <van-field :value="formData.customer_name"
                    rows="1"
                    type="textarea"
                    autosize label="客户名称" readonly />
                </van-cell-group>
                <van-cell-group>
                  <van-field :value="formData.order_date" label="下单日期" readonly />
                </van-cell-group>
                <van-cell-group class="deliver-box">
                  <van-field :value="formData.delivery_date" label="交付日期" readonly />
                  <van-tag color="#ED6A0C" v-if="isOverDue(formData.delivery_date)" class="overdue-tag" size="medium">已过期</van-tag>
                </van-cell-group>
                <van-cell-group>
                  <van-field :value="formData.remark"
                    rows="1"
                    type="textarea"
                    autosize
                    label="订单备注" readonly />
                </van-cell-group>
                <van-cell-group v-if="0">
                  <van-button style="margin: 10px;" 
                    :icon="fujian" 
                    type="info" size="small" plain
                    @click="viewPdf()">
                      查看附件</van-button>
                </van-cell-group>
            </div>
        </div> 
        <div class="detail-dashboard inner-box">
            <van-row type="flex" gutter="20" justify="center">
              <van-col span="12" style="text-align: right;">
                  <div class="h3">发动机</div>
                  <div class="kw-item">
                      <div class="item-label">
                          订单数量
                      </div>
                      <div class="item-content">
                          {{formData.order_engine_num}}
                      </div>
                  </div>
                  <div class="kw-item">
                      <div class="item-label">
                          生产数量
                      </div>
                      <div class="item-content">
                          {{formData.made_engine_num}}
                      </div>
                  </div>
                  <div class="kw-item">
                      <div class="item-label">
                          交付数量
                      </div>
                      <div class="item-content warn">
                          {{formData.delivery_engine_num}}
                      </div>
                  </div>
              </van-col>
              <van-col span="12 left-bord">
                  <div class="h3">发电机组</div>
                  <div class="kw-item">
                      <div class="item-label">
                          订单数量
                      </div>
                      <div class="item-content">
                          {{formData.order_genset_num}}
                      </div>
                  </div>
                  <div class="kw-item">
                      <div class="item-label">
                          生产数量
                      </div>
                      <div class="item-content">
                          {{formData.made_genset_num}}
                      </div>
                  </div>
                  <div class="kw-item">
                      <div class="item-label">
                          交付数量
                      </div>
                      <div class="item-content warn">
                          {{formData.delivery_genset_num}}
                      </div>
                  </div>
              </van-col>
            </van-row>
        </div>
        <div class="detail-others">
            <van-tabs 
            type="card" 
            v-model="active" 
            class="my-tab" 
            color="#1989fa" 
            animated 
            @change="changeTab">
              
            <van-tab title="评论" name="1">
                <div class="comment-list">
                    <van-steps direction="vertical" :active="0" :active-color="activeColor">
                       <van-step>
                            <div class="comment-list-item">
                                <div class="comment-toolbar">{{getOrderStatus()}}</div>
                              <!-- <div class="comment-toolbar"></div> -->
                            </div>
                       </van-step>
                        <van-step v-for="(comment, index) in comments" :key="index" >
                            <van-swipe-cell >
                                <div class="comment-list-item">
                                    <div v-if="comment.category == 1 || comment.category == 2">
                                        <div class="comment-title">【{{comment.user_name}}】{{comment.text_content}}</div>
                                        <div class="comment-toolbar">
                                            <span class="comment-date">
                                                {{comment.add_time}}
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="comment.category == 5">
                                        <div class="comment-title">
                                            【{{comment.user_name}}】{{comment.text_content}}</div>
                                        <div class="comment-files">
                                            <div class="comment-file-item" 
                                                v-for="(item,index) in getDocFileList(comment)"
                                                :key="index"
                                                @click="preview(item)"
                                                >
                                                <van-image class="file-image"
                                                  :src="getsrc(item.type)"
                                                />
                                                <span class="comment-file-item-cover">{{item.name}}</span>
                                            </div>
                                        </div>
                                            
                                        <div class="comment-toolbar">
                                           {{comment.add_time}}
                                        </div>
                                    </div>
                                </div>
                                <!--必须是评论 而且只能删除自己的评论-->
                                <template #right v-if="comment.category != 1 && comment.uid == uid">
                                    <van-button square text="删除" type="danger" class="delete-button" 
                                    @click="delComment(comment.id)" />
                                </template>
                            </van-swipe-cell>
                            
                        </van-step>
                      </van-steps>
                  </div>
                  
              </van-tab>
                
            <van-tab title="订单明细" name="2">
                <div class="tab-item2-content">
                    <order-detail-item :details="details" title="机组配置明细" v-if="0"></order-detail-item>
                    
                    <order-detail-config :details="details2" category="2" ></order-detail-config>
                    <order-detail-config :details="details1" category="1" ></order-detail-config>
                </div>
                
                <!-- <div class="genset-detail-box" ref="details2">
                
                </div> -->
                <!-- <div class="engine-detail-box" ref="details1">
                    
                </div> -->
            </van-tab>
            </van-tabs>
        </div>
        <!-- <div class="genset-detail-box" ref="details2">
        
        </div> -->
        <update-order-num ref="detailPop" :order="formData"></update-order-num>
        <div class="comment-box" :class="active == 1?'actived':''">
            <div class="flex-comment">
                <div class="comment-1">
                    
                    <van-cell-group>
                        <at-mention ref="atMention"  />
                    </van-cell-group>
                </div>
                <div class="comment-2">
                    <van-icon class="uploader" :name="uploader" size="40" @click="uploadDoc" />
                    <van-button
                        round type="info" size="mini"
                        @click="reply"
                     class="reply-btn">发表</van-button>
                </div>
                
            </div>
        </div>
        <upload-doc ref="popDoc"></upload-doc>
    </div>
</template>

<script>
    import { getErpOrderDetail, 
            updateErpOrderNum, 
            createErpOrderComment, 
            deleteErpOrderCOmment } from '../../api/task.js';
    // import PDFJS from "pdfjs-dist";
    import UpdateOrderNum from '../../components/UpdateOrderNum.vue';
    import UploadDoc from '../../components/UploadDoc.vue';
    import OrderDetailItem from '../../components/OrderDetailItem.vue';
    import OrderDetailConfig from '../../components/OrderDetailConfig.vue';
    import AtMention from '../../components/AtMention.vue';
    import { toast, checkLogin } from '../../utils/common.js';
    var id = null, order= null;
    var docTypes = ['doc','docx','xls','xlsx','ppt','pptx'];
    var pdfTypes = ['pdf'];
    
    export default {
        components:{
            UpdateOrderNum,
            UploadDoc,
            OrderDetailItem,
            OrderDetailConfig,
            AtMention
        },
        mounted(){
           
        },
        activated() {
            this.hideTab();
            this.smooth(); 
            id = this.$route.params.id;
            checkLogin().then(()=> {
                this.getDetail(id);
            })
        },
        data() {
            return {
                fujian: require('../../assets/images/fujian.png'),
                formData:{
                    order_no: '', // 订单编号
                    customer_name: '', // 客户名称
                    order_date: '',
                    delivery_date: '',
                    remark: '',
                    order_genset_num: 0,// 机组订单数量
                    order_engine_num: 0,// 发动机订单数量
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    delivery_genset_num: 0,// 交付机组数量
                    delivery_engine_num: 0,// 交付发动机数量
                    id: null, 
                },
                details:[],
                details1:[],
                details2:[],
                comments: [],
                value1: '',
                value2: '',
                showCalendar1: false,
                showCalendar2: false,
                minDate: new Date('2020', 1, 1),
                active: 1,
                activeColor: '#1989fa',
                uid: '',
                pdf: require('@/assets/images/file-pdf.png'),
                doc: require('@/assets/images/file-word.png'),
                // ait: require('@/assets/images/icon-aite.png'),
                //icon-uploader
                uploader: require('@/assets/images/icon-uploader.png'),
            }
            
        },
        methods:{
            viewPdf(){
                //var url = order.genset_pdf;
                var url = 'http://xjbc2.fr3.top/uploads/12103.docx';
                //this.$router.replace(`/erp/comment/view-doc?url=${encodeURI(url)}`);
                
                window.location.href = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
            },
            toHome(){
                this.$router.replace('/erp');
            },
            // deRemove(){
            //     var ball = this.$refs.ball;
            //     var doc = window.document;
            //     ball.removeEventListener('mousedown');
            //     ball.removeEventListener('mousemove');
            //     doc.removeEventListener('mouseup');
            // },
            smooth(){
               var ball = this.$refs.ball;
               var doc = window.document;
               var maxHeight = 120 + 300 - 50;
               var minHeight = 120 - 50;
               // 绑定事件
               //ball.removeEventListener('mousedown', onMousedown);
               ball.onmousedown = onMousedown;
               function onMousedown(evt){
                   evt.preventDefault();
                   //doc.removeEventListener('mousemove', onMove);
                   doc.onmousemove = onMove;
                   //doc.addEventListener('mousemove', onMove);
               }
               function onMove(evt){
                   evt.preventDefault();
                    requestAnimationFrame(()=>{
                        if(evt.pageY < maxHeight && evt.pageY > minHeight){
                            ball.style.transform = `translateY(${evt.pageY - 120}px)`;
                        }
                        
                    })
               }
               
               function onMouseup(evt){
                   evt.preventDefault();
                   doc.onmousemove = null;
               }
               doc.onmouseup = onMouseup;
            },
            getsrc(type){
                console.log('type', type);
                if(pdfTypes.includes(type)){
                    return this.pdf;
                }else if(docTypes.includes(type)){
                    return this.doc;
                }
            },
            preview(item){
                console.log('view',item);
                var link = encodeURI(item.url);
                if(pdfTypes.includes(item.type)){
                    this.$router.replace(`/erp/comment/view-pdf?url=${link}`)
                }else if(docTypes.includes(item.type)){
                    this.$router.replace(`/erp/comment/view-doc?url=${link}`)
                }
                
            },
            getDocFileList(comment){
                // console.log(comment);
                if(comment.category == 5){
                    var list = [];
                    for (var i = 0; i < comment.file_name.length; i++) {
                        var item = comment.file_name[i];
                        var ex = item.slice(item.lastIndexOf('.') + 1);
                        var type = '';
                        if(docTypes.includes(ex.toLowerCase())){
                            type = 'doc';
                        }else if(pdfTypes.includes(ex.toLowerCase())){
                            type = 'pdf';
                        }
                        list.push({
                            // url: `${this.pdf}`,
                            name: item,
                            type: type,
                            url: comment['file_url'][i]
                        })
                    }
                    return list;
                }
                return null;
            },
            uploadDoc(){
                this.$refs.popDoc.show = true;
            },
            getDetail(id){
                getErpOrderDetail(id).then((ret)=>{
                    console.log('data',ret.data);
                    order = ret.data;
                    this.formData.id = id;
                    this.formData.order_no = order.order_no;
                    this.formData.customer_name = order.customer_name;
                    this.formData.order_date = order.order_date;
                    this.formData.delivery_date = order.delivery_date;
                    this.formData.remark = order.remark;
                    
                    this.formData.order_genset_num = order.order_genset_num;
                    this.formData.order_engine_num = order.order_engine_num;
                    this.formData.made_genset_num = order.made_genset_num;
                    this.formData.made_engine_num = order.made_engine_num;
                    this.formData.delivery_genset_num = order.delivery_genset_num;
                    this.formData.delivery_engine_num = order.delivery_engine_num;
                    this.details = order.details;
                    this.details1 = order.details1;
                    this.details2 = order.details2;
                    //console.log(drawEngine, drawGenset);
                    // if(this.details1){
                    //     drawEngine(this.details1, this.$refs.details1);
                    // }
                    // if(this.details2){
                    //     drawGenset(this.details2, this.$refs.details2);
                    // }
                    // drawEngine();
                    this.comments = order.comments;
                    this.uid = order.uid;
                });
            },
            updateNum(formData){
                var data = {
                    made_genset_num: formData.made_genset_num,// 生产机组数量
                    made_engine_num: formData.made_engine_num,// 生产发动机数量
                    delivery_genset_num: formData.delivery_genset_num,// 交付机组数量
                    delivery_engine_num: formData.delivery_engine_num,// 交付发动机数量
                };
                updateErpOrderNum(id, data).then(()=> {
                    toast('更新进度成功', 'success');
                    this.getDetail(id);
                }).catch((err)=>{
                    toast(err.message|| '更新进度失败');
                })
            },
            popDetail(){
                var pop = this.$refs.detailPop;
                //pop.formData = this.formData;
                pop.setOrder(order);
                pop.show = true;
            },
            getCateName(cate){
                if(cate == 1){
                    return '发动机';
                }
                if(cate == 2){
                    return '发电机组';
                }
                return '其它';
            },
            getDateTime(str){
                if(str){
                  var time = new Date(str).getTime();
                  return Math.floor(time/1000);
                }
                return null;
            },
            changeTab(name){
                //console.log(title);
                this.active = name;
            },
            reply(){
                var content = this.$refs.atMention.getText();
                var { text, users } = this.parseText(content);
                if(!text){
                    console.log('空');
                    return;
                }
                var data = {
                    'order_id': id,
                    'file_url': '',
                    'category': 2,
                    'text_content': text,
                    'users': users.join(','),
                };
                
                console.log('huifu', data);
                createErpOrderComment(data).then(()=>{
                    toast('评论成功');
                    this.$refs.atMention.clear();
                    this.getDetail(id);
                }).catch((err)=>{
                    toast(err.message||'评论失败', 'fail');
                })
            },
            // 解析编辑器内容
            parseText(content){
                 var divEle = document.createElement('div');
                 divEle.innerHTML = content;
                 console.log(divEle.innerText); 
                 var text = divEle.innerText;
                 
                var mens = divEle.querySelectorAll('.mention');
                var users  = [];
                mens.forEach(function(m){
                    users.push(m.getAttribute('data-id'));
                })
                return {
                    text,
                    users
                }
            },
            uploadFile(files){
                var data = {
                    'order_id': id,
                    'file_url': files.file_url,
                    'file_name': files.file_name,
                    'category': 5,
                    'text_content': '',
                };
                createErpOrderComment(data).then(()=>{
                    toast('上传成功');
                    this.comment = '';
                    this.getDetail(id);
                }).catch((err)=>{
                    toast(err.message||'上传失败', 'fail');
                })
            },
            getOrderStatus(){
                if(!order){
                    return '';
                }
                // 如果机组 和发动机 订单数量== 发货数量 ，已完成
                if(order.order_genset_num == order.delivery_genset_num
                && order.order_engine_num == order.delivery_engine_num){
                    this.activeColor = '#969799';
                    return '订单已完成';
                }
                this.activeColor = '#1989fa';
                return '订单进行中';
            },
            delComment(cid){
                console.log('id', cid);
                deleteErpOrderCOmment(cid).then(()=>{
                    toast('删除成功');
                    this.getDetail(id);
                }).catch((err)=>{
                    toast(err.message||'删除失败', 'fail');
                })
            },
            // 是否过期
            isOverDue(date){
                if(date){
                    var d = new Date(date);
                    if(Date.now() > d.getTime()){
                        if(order.order_genset_num == order.delivery_genset_num
                        && order.order_engine_num == order.delivery_engine_num){
                            return false;
                        }
                        return true;
                    }
                    return false;
                }
                return false;
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
        }
    }
</script>

<style scoped>
    /* 导航区域 */
    .nav-area{
        position: fixed;
        width: 60px;
        height: 300px;
        top: 100px;
        right: 5px;
        /* background-color: #f2f200; */
        z-index: 888;
        /* border: 1px solid #ccc; */
        pointer-events:none;
    }
    .nav-area .nav-button{
        border-radius: 50%;
        height: 50px;
        width: 50px;
        transition: transform linear .1s;
        pointer-events:all;
        background-color: rgba(64, 169, 255, 0.7);
    }
    .detail-basic{
        color: #000;
    }
    .my-tab{
        margin-left: 10px;
        margin-right: 10px;
    }
    .tab-item2-content{
        width: 100vw;
        margin-left: -10px;
    }
    .home-tab{
        display: none !important;
    }
    .item-add-box{
        /* text-align: center; */
        margin: 10px;
    }
    .detail-basic .van-cell{
        font-size: 14px;
    }
    .footer-box{
        margin: 16px;
    }
    .detail-dashboard{
        margin: 15px 10px;
        font-size: 16px;
        border-radius: 5px;
        /* font-weight: bold; */
        -webkit-box-shadow: 0 0 6px 3px #ddd ;
        box-shadow: 0 0 6px 3px #ddd  ;
        /* transform: skew(-10deg); */
    }
    .detail-dashboard .h3{
        font-size: 16px;
        text-align: center;
        margin: 1rem 0;
        font-weight: bold;
    }
    .main .left-bord{
        box-sizing: border-box;
        border-left: 1px solid #f2f2f2;
    }
    .kw-item{
        display: flex;
        margin: 0.6rem 0;
    }
    .kw-item .item-label{
        min-width: 8rem;
        text-align: right;
        padding-right: 1rem;
    }
    .kw-item .item-content{
        font-size: 18px;
        font-weight: bold;
        overflow-wrap:anywhere;
    }
    .item-order-no{
        position: relative;
    }
    .item-order-no .btn-edit{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .comment-box{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        /* height: 100px; */
        text-align: center;
        transform: translateY(100%);
        /* transition: transform ease .3s; */
        background: #fff;
    }
    .comment-box.actived{
        transform: translateY(0);
        transition: transform ease .3s;
    }
    .flex-comment{
        position: relative;
        display: flex;
        font-size: 14px;
        align-items: start;
        border-top: 1px solid #f2f2f2;
    }
    .flex-comment .comment-1{
        width: 100%;
        /* background-color: #f2f2f2; */
    }
    .flex-comment .comment-2{
        min-width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 90px;
        transform: translateY(8px);
    }
    .comment-list{
        text-align: left;
        padding-bottom: 100px;
        margin-top: 10px;
        line-height: 3rem;
        font-size: 14px;
    }
    .comment-list-item{
        color: #666;
        box-sizing: border-box;
    }
    .comment-list .comment-title{
        padding-right: 10px;
        padding-top: 5px;
        border-top:1px dashed #f2f2f2 ;
    }
    
    .comment-list .comment-toolbar{
        display: flex;
        justify-content: space-between;
        padding: 2px 2px;
        /* border-bottom: 1px dashed #f2f2f2; */
        padding-bottom: 5px;
    }
    .comment-list .comment-date{
        color: #999;
        font-size: 13px;
    }
    .deliver-box{
        position: relative;
    }
    .deliver-box .overdue-tag{
        position: absolute;
        right: 10px;
        top: 15px;
    }
    .toupload{
        display: block;
        /* min-width: 70px; */
       /* position: absolute;
        right: 15px;
        top: 15px; */
        border-width: 0;
        /* border-bottom:1px solid #1989fa; */
        text-decoration: underline;
    }
    .reply-btn{
        display: inline-block;
        width: 55px;
       /* position: absolute;
        bottom: 10px;
        right: 18px; */
    }
    .file-item{
        margin-top: 10px;
    }
    .comment-files{
        display: flex;
        flex-wrap: wrap;
        justify-content:start;
        /* display: block; */
    }
    .comment-files .comment-file-item{
        position: relative;
        margin: 10px 0;
        padding: 0 3px 0 0;
        text-align: center;
    }
    .comment-files .file-image{
        width: 70px;
        height: 70px;
        display: block;
        margin: auto;
    }
    .comment-files .comment-file-item-cover{
        
        box-sizing: border-box;
        padding: 3px;
        /* color: #fff; */
        font-size: 12px;
        text-align: center;
        width: 100px;
        /* display: hidden; */
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
       
    }
    .order-detail-item img{
        width: 100%;
    }
</style>