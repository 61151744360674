<template>
    <van-popup closeable v-model="show" :style="{ width: '95%'}">
        <van-form @submit="onSubmit" class="my-form" v-if="order">
            <van-field
                disabled
                :value="order.order_no"
                name="order_no"
                label="订单编号"
                maxlength="50"
              />
              <van-field
                disabled
                :value="order.customer_name"
                type="textarea"
                name="customer_name"
                autosize
              />
              <!-- <van-field
                disabled
                :value="formData.remark"
                type="textarea"
                name="remark"
                label="备注信息"
                autosize
              /> -->
            <div class="item-form-num inner-box">
                <van-row>
                  <van-col span="4"></van-col>
                  <van-col span="10">发动机</van-col>
                  <van-col span="10">发电机组</van-col>
                </van-row>
                <van-row>
                  <van-col span="4" class="from-num-label">订单数量</van-col>
                  <van-col span="10">
                      <van-field name="order_engine_num" label="">
                        <template #input>
                          <van-stepper v-model="order.order_engine_num" min=""  disabled/>
                        </template>
                      </van-field>
                  </van-col>
                  <van-col span="10">
                      <van-field name="order_genset_num" label="">
                        <template #input>
                          <van-stepper v-model="order.order_genset_num" min="" disabled />
                        </template>
                      </van-field>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="4" class="from-num-label">生产数量</van-col>
                  <van-col span="10">
                      <van-field name="made_engine_num" label="">
                        <template #input>
                          <van-stepper 
                            v-model="formData.made_engine_num" min="" 
                            :max="order.order_engine_num" />
                        </template>
                      </van-field>
                  </van-col>
                  <van-col span="10">
                      <van-field name="made_genset_num" label="">
                        <template #input>
                          <van-stepper 
                          v-model="formData.made_genset_num" min=""
                           :max="order.order_genset_num" />
                        </template>
                      </van-field>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="4" class="from-num-label">交付数量</van-col>
                  <van-col span="10">
                      <van-field name="delivery_engine_num" label="">
                        <template #input>
                          <van-stepper 
                            v-model="formData.delivery_engine_num" min=""
                            :max="order.order_engine_num"/>
                        </template>
                      </van-field>
                  </van-col>
                  <van-col span="10">
                      <van-field name="delivery_genset_num" label="">
                        <template #input>
                          <van-stepper 
                            v-model="formData.delivery_genset_num" min=""
                             :max="order.order_genset_num" />
                        </template>
                      </van-field>
                  </van-col>
                </van-row>
            </div>
              <div class="footer-box">
                <van-button round block type="info" native-type="submit">确认</van-button>
              </div>
        </van-form>
    </van-popup>
</template>

<script>
    import { toast } from '../utils/common.js'
    // 订单修改进度
    export default{
        name: 'updateOrderNum',
        data() {
            return{
                formData:{
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    delivery_genset_num: 0,// 交付机组数量
                    delivery_gengine_num: 0,// 交付发动机数量
                    genset_max_num: 0, // 限制机组最大数量
                    engine_max_num: 0, // 限制发动机最大数量
                    
                    // made_genset_num: this.order.made_genset_num,// 生产机组数量
                    // made_engine_num: this.order.made_engine_num,// 生产发动机数量
                    // delivery_genset_num: this.order.delivery_genset_num,// 交付机组数量
                    // delivery_engine_num: this.order.delivery_engine_num,// 交付发动机数量
                    // genset_max_num: this.order.order_genset_num, // 限制机组最大数量
                    // engine_max_num: this.order.order_engine_num, // 限制发动机最大数量
                },
                order: null,
                show:false,
            }
        },
        methods:{
            onSubmit(){
                // 比较 是否改变
                if(this.formData.made_genset_num == this.order.made_genset_num
                  && this.formData.made_engine_num == this.order.made_engine_num
                  && this.formData.delivery_genset_num == this.order.delivery_genset_num
                  && this.formData.delivery_engine_num == this.order.delivery_engine_num){
                    return toast('数据未修改');
                }
                this.show = false;
                
                this.$parent.updateNum(this.formData);
            },
            setOrder(item){
                console.log(item);
                this.order = item;
                this.formData.made_genset_num = item.made_genset_num;
                this.formData.made_engine_num = item.made_engine_num;
                this.formData.delivery_genset_num = item.delivery_genset_num;
                this.formData.delivery_engine_num = item.delivery_engine_num;
                this.formData.genset_max_num = item.order_genset_num;
                this.formData.engine_max_num = item.order_engine_num;
            },
            reset(){
                this.formData = {
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    delivery_genset_num: 0,// 交付机组数量
                    delivery_engine_num: 0,// 交付发动机数量
                    genset_max_num: 0, // 限制机组最大数量
                    engine_max_num: 0, // 限制发动机最大数量
                };
            },
        }
    }
</script>

<style scoped>
    .footer-box{
        padding: 10px;
    }
    .my-form{
        position: relative;
        margin-top: 2rem;
    }
    .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .item-form-num{
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
    .from-num-label{
        line-height: 45px;
    }
</style>