import { render, staticRenderFns } from "./AtMention.vue?vue&type=template&id=790f6dec&scoped=true&"
import script from "./AtMention.vue?vue&type=script&lang=js&"
export * from "./AtMention.vue?vue&type=script&lang=js&"
import style0 from "./AtMention.vue?vue&type=style&index=0&id=790f6dec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790f6dec",
  null
  
)

export default component.exports